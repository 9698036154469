<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between">
          <div class="col-sm-8">
            <div class="heading">
              <h2 v-if="first_name">{{first_name | capitalize}}'s Reports</h2>
              <h2 v-else>{{user_email}}'s Reports</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div> 
        </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table_card summry_report">
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                <div class="dataTables_length" id="datatable_length">
                  <label
                    >Reports
                    <select
                      name="datatable_length"
                      aria-controls="datatable"
                      class=""
                      v-model.number="itemsPerPage"
                      @change="getProductsBysearch"
                    >
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                    </select>
                    entries</label
                  >
                </div>
                <div id="datatable_filter" class="dataTables_filter">
                  <label
                    >Search:<input
                      type="search"
                      class="" 
                      placeholder="Type of Assessment"
                      aria-controls="datatable"
                      v-model="listConfig.search_string"
                      v-on:keyup.enter="getProductsBysearch"
                  />
                  <a href="javascript:void(0)" @click="getProductsBysearch" class="search_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                    </a>
                    <a href="javascript:void(0)" v-if="listConfig.search_string" @click="listConfig.search_string = null; getProductsBysearch()" class="crossicon">
                        X
                    </a>
                  </label>
                </div>
              </div>
            <div class="table-responsive table_audit_log">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th class="sort">
                      <SortIcons
                        :lable="'Date Of Assessment  '"
                        :listConfig="listConfig"
                        :sortBy="'assessment_date'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th class="sort">
                      <SortIcons
                        :lable="'Type Of Assessment'"
                        :listConfig="listConfig"
                        :sortBy="'type_of_assessment'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in reports.records" :key="indx">
                    <td>
                      <p class="">{{ report.formated_date ? report.formated_date : report.formated_assessment_date }}</p>
                    </td>
                    <td>
                      <p>{{ first_name }}</p>
                    </td>
                    <td>
                      <p class="email_tag">
                        <a :href="'mailto:' + report.user_email">{{
                          report.user_email
                        }}</a>
                      </p>
                    </td>
                    <!-- <td>
                    <p>{{report.location ? report.location : 'Not Available'}}</p>
                  </td> -->
                    <td>
                      <p>
                        {{
                          report.type_of_assessment
                            ? report.type_of_assessment
                            : "Not Available"
                        }}
                      </p>
                    </td>

                    <td>
                      <div class="edit_btn">
                        <p>
                          <router-link
                            :to="{
                              name: 'view_user_reports',
                              params: { userid:$route.params.id,id: report.u_id },
                            }"
                            target="_blank"
                            title="View Report"
                          >
                            <img
                              class="edit-icon"
                              src="../../assets/images/dashboard/View_Icon.svg"
                              alt="img"
                            />
                          </router-link>
                          <!-- <a :href="report.summary_report" target="_blank">
                          <img
                            class="edit-icon"
                            src="../../assets/images/dashboard/View_Icon.svg"
                            alt="img"
                          />
                        </a> -->
                          &nbsp;
                          <a
                            :href="'#'"
                            @click="downloadZipFile(report.summary_report)"
                            class="d-none"
                          >
                            <img
                              class="edit-icon"
                              src="../../assets/images/dashboard/download.svg"
                              alt="img"
                            />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                   <tr v-if="data_length==0"><td colspan="5" class="text-center">No Records found</td></tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ positionText }}</p>
            <VPagination
              v-if="data_length"
              class="dataTables_paginate"
              :totalPages="pages.length"
              :perPage="itemsPerPage"
              :currentPage.sync="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>

      </div>
    </main>
    </div>
</template>


<script>
import "vue-select/dist/vue-select.css";
import AuthService from "@/services/AuthService"; 
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";

export default {
  name: "my_reports",
  components: { 
    VPagination,
    SortIcons,
  },
  data() {
    return { 
      first_name: "",
      user_email: "",
      reports: [],  
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      data_length: 0,
    };
  },
  computed: { 
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getSingleUserReport();
    },
  }, 
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "assessment_date",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
    }; 

    this.getSingleUserReport();
  },
  methods: {
    onPageChange(page) {
      console.log(page);
      this.currentPage = page;
    },
    sorting($event) {
      console.log($event);
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getSingleUserReport();
    },
    getProductsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getSingleUserReport();
    },
    getSingleUserReport() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/employee-reports/"+this.$route.params.id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: _this.listConfig,
      };

      _this
        .axios(config)
        .then(({ data }) => {
          if(data.success)
          {
            console.log(data);
            _this.first_name = data.first_name;
            _this.user_email = data.user_email;
            _this.reports = data;
            _this.data_length = data.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          _this.$router.push({ name: "all_user" });
          //if (response.data.type == false) {
          //  _this.$router.push({ name: "login" });
          //}
        });
    },
    downloadZipFile(url) {
      AuthService.downloadZipFile(url).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Summary_Report.html");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }, 
  },
};

</script>